<template>
  <div class="probability">
    <div class="probability__text">Probabilidad de que se gane el Parlay:</div>
    <div class="probability__percent">
      <div>{{ percent }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Probability',
  props: {
    percent: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.probability {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    font-family: Circular-Std-Book, sans-serif;
    font-size: 1.5em;
    letter-spacing: -1.45px;
    color: #adadad;
  }

  &__percent {
    height: 3.7em;
    width: 3.7em;
    min-width: 3.7em;
    border-radius: 50%;
    background-color: #7ed321;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1em;

    & > div {
      font-family: Circular-Std-Bold, sans-serif;
      font-size: 1.5em;
      letter-spacing: -0.45px;
    }
  }

  @media screen and (max-width: 508px) {
    font-size: 0.8rem;
    padding: 0 1rem;
  }

  @media screen and (max-width: 388px) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: 342px) {
    font-size: 0.6rem !important;
  }
}
</style>
